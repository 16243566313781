import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Particles from "react-particles-js"

const CtaComponent = () => {
  return (
    <div className="cta position-relative">
      <div className="particlesContainer" style={{ backgroundColor: "white" }}>
        <Container className="h-100">
          <Row className="align-items-center justify-content-center h-100 text-center text-md-left">
            <Col md={5} xs={12} style={{ zIndex: 2 }}>
              <div>
                <h1 className="gradient-text font-weight-300">Conheça</h1>
              </div>
              <div>
                <h1 className="gradient-text font-weight-700">Nosso Blog</h1>
              </div>
            </Col>
            <Col md={3} xs={12} className="text-center" style={{ zIndex: 2 }}>
              <a
                href="/blog"
                className="btn btn-primary"
              >
                Saiba mais
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <Particles params={particles} className="bgParticles" />
    </div>
  )
}

let particles = {
  particles: {
    number: {
      value: 70,
      opacity: 0.3
    },
    size: {
      value: 1,
    },
    move: {
      speed: 6,
    },
    color: {
      value: "#000",
    },
    links: {
      color: "#000",
      distance: 105,
      enable: true,
      opacity: 0.5,
      width: 0.5,
    },
    line_linked: {
      enable: true,
      color: {
        value: "#000",
      },
    },
  },
}

if (typeof window !== "undefined" && window.innerWidth < 900) {
  particles = {
    particles: {
      number: {
        value: 35,
      },
      links: {
        color: "#000",
        distance: 60,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      size: {
        value: 1,
      },
      move: {
        speed: 4,
      },
      color: {
        value: "#000",
      },
      line_linked: {
        enable: true,
        color: {
          value: "#ddd",
        },
      },
    },
  }
}

export default CtaComponent
